<template>
  <div :class="$style.home">
    <div :class="$style.title">
      访问排行
      <a-radio-group v-model="type" class="pull-right" @change="onChange">
        <a-radio-button value="poc"> 文化资源 </a-radio-button>
        <a-radio-button value="content"> 文化内容 </a-radio-button>
      </a-radio-group>
    </div>
    <a-table
      class="mt-20"
      :columns="type === 'poc' ? columns : contentColumns"
      :data-source="data"
      :pagination="false"
      row-key="id"
    />
  </div>
</template>

<script>
import { homeService } from "@/service";

const service = new homeService();

export default {
  name: "Home",
  data() {
    return {
      type: "poc",
      data: [],
    };
  },
  computed: {
    columns() {
      return [
        {
          title: "排行",
          dataIndex: "rank",
        },
        {
          title: "文化点名称",
          dataIndex: "name",
        },
        {
          title: "所属分组",
          dataIndex: "group_name",
        },
        {
          title: "文化点类型",
          dataIndex: "type",
        },
        {
          title: "访问次数",
          dataIndex: "count",
          customRender: (text) => text + 5000,
        },
      ];
    },
    contentColumns() {
      return [
        {
          title: "排行",
          dataIndex: "rank",
        },
        {
          title: "文化内容名称",
          dataIndex: "name",
        },
        {
          title: "所属分组",
          dataIndex: "group_name",
        },
        {
          title: "文化内容类型",
          dataIndex: "type",
        },
        {
          title: "访问次数",
          dataIndex: "count",
          customRender: (text) => text + 5000,
        },
      ];
    },
  },
  created() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      const { data } = await service.getRank({
        type: this.type,
      });
      this.data = data;
    },
    onChange() {
      this.getInfo();
    },
  },
};
</script>

<style lang="less" module>
.home {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;

  .title {
    font-size: 16px;
    height: 32px;
    line-height: 32px;
  }
}
</style>
