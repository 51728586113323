<template>
  <div :class="$style.notice">
    <i class="ri-notification-2-line" />
    <div :class="$style.content" class="ellipsis">公告</div>
    <span :class="$style.more">
      更多
      <i class="ri-arrow-right-s-line" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {}
  },
}
</script>

<style lang="less" module>
.notice {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  background-color: #fff;
  padding: 0 20px;
  font-size: 13px;

  i {
    font-size: 18px;
  }

  .content {
    flex: 1;
    margin-left: 10px;
    line-height: 1;
  }

  .more {
    color: @primary-color;
    font-size: 12px;

    i {
      vertical-align: -4px;
    }
  }
}
</style>
