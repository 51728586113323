<template>
  <div :class="$style.home">
    <div :class="$style.left">
      <notice />
      <build />
      <statistical />
      <Area />
      <rank />
    </div>
    <div :class="$style.right">
      <banner />
      <customer />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import area from './area.vue'
import banner from './banner.vue'
import build from './build.vue'
import customer from './customer.vue'
// import help from './help.vue'
import notice from './notice.vue'
import rank from './rank.vue'
import statistical from './statistical.vue'

export default {
  name: 'home',
  components: {
    notice,
    statistical,
    Area: area,
    rank,
    // help,
    customer,
    banner,
    build,
  },
  data() {
    return {}
  },
  created() {
    this.getCustomInfo()
  },
  methods: {
    ...mapActions(['getCustomInfo']),
  },
}
</script>

<style lang="less" module>
.home {
  display: flex;
  width: 100%;

  .left {
    flex: 1;
    margin-right: 20px;
  }

  .right {
    width: 280px;
  }
}
</style>
