<template>
  <div :class="$style.home">
    <div :class="$style.title">
      位置分布
      <a-radio-group v-model="type" class="pull-right" @change="typeChange">
        <a-radio-button value="poc"> 文化资源 </a-radio-button>
        <a-radio-button value="content"> 文化内容 </a-radio-button>
      </a-radio-group>
    </div>
    <div :class="$style.content">
      <div id="map" :class="$style.map" />
      <div :class="$style.rank">
        <a-table
          :columns="type === 'poc' ? columns : contentColumns"
          :data-source="rankData"
          :pagination="false"
          row-key="id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Scene } from '@antv/l7'
import { DrillDownLayer } from '@antv/l7-district'
import { GaodeMap } from '@antv/l7-maps'

import { homeService } from '@/service'

const service = new homeService()

export default {
  name: 'Home',
  data() {
    return {
      type: 'poc',
      provinceData: [],
      cityData: [],
      countyData: [],
      level: 'province',
      aeraId: null,
      rankData: [],
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '地区',
          dataIndex: 'region_name',
        },
        {
          title: '文化点数量',
          dataIndex: 'count',
        },
      ]
    },
    contentColumns() {
      return [
        {
          title: '地区',
          dataIndex: 'region_name',
        },
        {
          title: '文化内容数量',
          dataIndex: 'count',
        },
      ]
    },
  },
  created() {
    this.get()
  },
  mounted() {
    const scene = new Scene({
      id: 'map',
      logoVisible: false,
      map: new GaodeMap({
        style: 'blank',
        center: [116.2825, 39.9],
        pitch: 11,
        zoom: 2,
        minZoom: 2,
        maxZoom: 10,
        token: 'a126f5552c0c9d3a916de023b599e0bc',
      }),
    })
    scene.on('loaded', () => {
      new DrillDownLayer(scene, {
        provinceData: this.provinceData,
        cityData: this.cityData,
        countyData: this.countyData,
        // provinceData: this.provinceData,
        // cityData: [],
        // joinBy: ['adcode', 'adcode'],
        // city: { joinBy: ['NAME_CHN', 'NAME_CHN'] },
        depth: 1,
        viewStart: 'Country',
        viewEnd: 'City',
        fill: {
          color: {
            field: 'NAME_CHN',
            values: ['#feedde', '#fdd0a2', '#fdae6b', '#fd8d3c', '#e6550d', '#a63603'],
          },
        },
        popup: {
          enable: true,
          Html: props =>
            // this.aeraId = props.adcode
            // const { data } = await service.getRegion({
            //   type: this.type,
            //   level: this.level,
            //   parent_id: this.aeraId,
            // })
            // return `<span>${props.NAME_CHN}</span>`
            `<span>${props.NAME_CHN}-${props.value || 0}</span>`,
        },
        drillDownEvent: async props => {
          const { data } = await service.getRegion({
            type: this.type,
            level: props.level === 1 ? 'city' : 'county',
            parent_id: String(props.adcode),
          })
          this.rankData = data.splice(0, 4)
          if (props.level === '1') {
            this.cityData.push(data)
          }
          if (props.level === 'city') {
            this.countyData.push(data)
          }
        },
      })
    })
  },
  methods: {
    async get() {
      const { data } = await service.getRegion({
        type: this.type,
        level: this.level,
        parent_id: this.aeraId,
      })
      this.provinceData = data
      this.rankData = data.splice(0, 4)
    },
    typeChange(e) {
      this.type = e.target.value
      this.get()
    },
  },
}
</script>

<style lang="less" module>
.home {
  height: 404px;
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;

  .title {
    height: 32px;
    line-height: 32px;
    font-size: 16px;
  }
  .content {
    display: flex;
    margin-top: 20px;

    .map {
      flex: 1;
      height: 318px;
      position: relative;
      margin-right: 50px;
    }

    .rank {
      width: 260px;
    }
  }
}
</style>
