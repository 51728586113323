<template>
  <div :class="$style.build">
    <div :class="$style.header">
      五步搭建你的文化云
      <span :class="$style.btn" @click="showView()">
        {{ show ? '收起' : '展开' }}
        <i v-if="show" class="ri-arrow-up-s-line" />
        <i v-else class="ri-arrow-down-s-line" />
      </span>
    </div>
    <div v-show="show" :class="$style.content">
      <div :class="$style.left">
        <div
          v-for="(item, index) in data"
          :key="index"
          :class="[$style.item, active === index ? $style.itemActive : null]"
          @click="onClick(index)"
        >
          <img v-if="index === active" :src="oss + item.imgActive" />
          <img v-else :src="oss + item.img" />
          {{ item.name }}
        </div>
      </div>
      <div :class="$style.right">
        <div v-if="active === 0" :class="$style.step1">
          <div :class="$style.box">
            <div v-for="(item, index) in activeData" :key="index" :class="$style.item">
              <img :src="oss + item.img" />
              <div>{{ item.name }}</div>
            </div>
          </div>
          <p :class="$style.tip">文化点是文化云的基石</p>
          <BjButton type="primary" @click="page_to('resourcesPointAdd')">立即创建</BjButton>
        </div>
        <div v-if="active === 1" :class="$style.step2">
          <div :class="$style.box">
            <div v-for="(item, index) in activeData" :key="index" :class="$style.item">
              <img :src="oss + item.img" />
              <div>{{ item.name }}</div>
            </div>
          </div>
          <p :class="$style.tip">用多种类型丰富文化云</p>
          <BjButton type="primary" @click="page_to('contentManage')">立即创建</BjButton>
        </div>
        <div v-if="active === 2" :class="$style.step3">
          <div :class="$style.box">
            <div v-for="(item, index) in activeData" :key="index" :class="$style.item">
              <img :src="oss + item.img" />
              <div>{{ item.name }}</div>
            </div>
          </div>
          <p :class="$style.tip">用多种类型丰富文化云</p>
          <BjButton type="primary" @click="page_to('')">立即创建</BjButton>
        </div>
        <div v-if="active === 3" :class="$style.step4">
          <div :class="$style.box">
            <div v-for="(item, index) in activeData" :key="index" :class="$style.item">
              <div :style="back(item.back)" :class="$style.head" />
              <img :src="oss + item.img" />
              <div>{{ item.name }}</div>
            </div>
          </div>
          <p :class="$style.tip">用多种类型丰富文化云</p>
          <BjButton type="primary" @click="page_to('terminalManage')">立即创建</BjButton>
        </div>
        <div v-if="active === 4" :class="$style.step5">
          <div :class="$style.box">
            <div v-for="(item, index) in activeData" :key="index" :class="$style.item">
              <img :src="oss + item.img" />
              <div>{{ item.name }}</div>
            </div>
          </div>
          <p :class="$style.tip">用多种类型丰富文化云</p>
          <BjButton type="primary" @click="page_to('')">立即创建</BjButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      show: false,
      data: [
        {
          name: '创建文化点',
          img: '/manage/icon/home/setp01_d.png',
          imgActive: '/manage/icon/home/setp01.png',
          data: [
            {
              name: '1.整理资源',
              img: '/manage/icon/home/setp01_icon01.png',
            },
            {
              name: '2.梳理关系',
              img: '/manage/icon/home/setp01_icon02.png',
            },
            {
              name: '3.建立点位',
              img: '/manage/icon/home/setp01_icon03.png',
            },
            {
              name: '4.配置主页',
              img: '/manage/icon/home/setp01_icon04.png',
            },
          ],
        },
        {
          name: '创作文化内容',
          img: '/manage/icon/home/setp02_d.png',
          imgActive: '/manage/icon/home/setp02.png',
          data: [
            {
              name: '各种类型内容',
              img: '/manage/icon/home/setp02_icon01.png',
            },
            {
              name: '多渠道内容来源',
              img: '/manage/icon/home/setp02_icon02.png',
            },
            {
              name: '丰富的内容组件',
              img: '/manage/icon/home/setp02_icon03.png',
            },
            {
              name: '支持用户互动',
              img: '/manage/icon/home/setp02_icon04.png',
            },
          ],
        },
        {
          name: '添加文化应用',
          img: '/manage/icon/home/setp03_d.png',
          imgActive: '/manage/icon/home/setp03.png',
          data: [
            {
              name: '各种应用支持',
              img: '/manage/icon/home/setp03_icon01.png',
            },
            {
              name: '自由订购时长',
              img: '/manage/icon/home/setp03_icon02.png',
            },
            {
              name: '前端随心配置',
              img: '/manage/icon/home/setp03_icon03.png',
            },
            {
              name: '专属管理后台',
              img: '/manage/icon/home/setp03_icon04.png',
            },
          ],
        },
        {
          name: '配置文化云前端',
          img: '/manage/icon/home/setp04_d.png',
          imgActive: '/manage/icon/home/setp04.png',
          data: [
            {
              name: '多种形态前端',
              img: '/manage/icon/home/setp04_icon01.png',
              back: 'linear-gradient(270deg, #1D6FFF 0%, #54ADFF 100%)',
            },
            {
              name: '模块化搭建页面',
              img: '/manage/icon/home/setp04_icon02.png',
              back: 'linear-gradient(270deg, #8259ED 0%, #C087CF 100%)',
            },
            {
              name: '主题随心切换',
              img: '/manage/icon/home/setp04_icon03.png',
              back: 'linear-gradient(90deg, #FF4895 0%, #EC0081 100%)',
            },
            {
              name: '支持外部链接',
              img: '/manage/icon/home/setp04_icon04.png',
              back: 'linear-gradient(90deg, #56DBD1 0%, #00BDBA 100%)',
            },
          ],
        },
        {
          name: '推广运营文化云',
          img: '/manage/icon/home/setp05_d.png',
          imgActive: '/manage/icon/home/setp05.png',
          data: [
            {
              name: '场景结合',
              img: '/manage/icon/home/setp05_icon01.png',
            },
            {
              name: '文创结合',
              img: '/manage/icon/home/setp05_icon02.png',
            },
            {
              name: '数字资产',
              img: '/manage/icon/home/setp05_icon03.png',
            },
            {
              name: '商业应用',
              img: '/manage/icon/home/setp05_icon04.png',
            },
          ],
        },
      ],
      active: 0,
      activeData: [],
    }
  },
  created() {
    this.activeData = this.data[this.active].data
  },
  methods: {
    onClick(index) {
      this.active = index
      this.activeData = this.data[this.active].data
    },
    back(color) {
      return { background: color }
    },
    showView() {
      this.show = !this.show
    },
    page_to(_name) {
      if (_name === 'resourcesPointAdd') {
        this.$router.push({
          name: _name,
          params: { id: 'add' },
        })
        return
      }
      this.$router.push({
        name: _name,
      })
    },
  },
}
</script>

<style lang="less" module>
.build {
  background-color: #fff;
  margin-top: 20px;

  .header {
    position: relative;
    padding: 20px;
    font-size: 16px;

    .btn {
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 12px;
      color: @primary-color;
      cursor: pointer;

      i {
        font-size: 18px;
        vertical-align: -5px;
      }
    }
  }

  .content {
    padding: 20px;
    display: flex;
    user-select: none;

    .left {
      width: 172px;

      .item {
        height: 50px;
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 50px;
        border-radius: 50px 0 0 50px;
        cursor: pointer;

        img {
          width: 24px;
          height: 24px;
          object-fit: cover;
          margin: 0 10px 0 20px;
        }
      }

      .item-active {
        color: @primary-color;
        background-color: rgba(19, 112, 223, 0.03);
      }
    }

    .right {
      flex: 1;
      background-color: rgba(19, 112, 223, 0.03);

      .step1 {
        text-align: center;

        .box {
          display: flex;
          justify-content: space-between;
          width: 534px;
          margin: 47px auto 55px;

          .item {
            width: 104px;
            height: 104px;
            border-radius: 100%;
            text-align: center;
            background-color: #fff;

            img {
              width: 32px;
              height: 32px;
              margin-bottom: 6px;
              margin-top: 24px;
            }
          }
        }

        .tip {
          font-size: 14px;
          color: #333;
        }
      }

      .step2 {
        text-align: center;

        .box {
          display: flex;
          justify-content: space-between;
          width: 540px;
          margin: 40px auto 46px;

          .item {
            width: 120px;
            height: 120px;
            text-align: center;
            background-color: #fff;

            img {
              width: 40px;
              height: 40px;
              margin-bottom: 12px;
              margin-top: 24px;
            }
          }
        }

        .tip {
          font-size: 14px;
          color: #333;
        }
      }

      .step3 {
        text-align: center;

        .box {
          display: flex;
          justify-content: space-between;
          width: 540px;
          margin: 40px auto 46px;

          .item {
            width: 120px;
            height: 120px;
            text-align: center;
            background-color: #fff;

            img {
              width: 40px;
              height: 40px;
              margin-bottom: 12px;
              margin-top: 24px;
            }
          }
        }

        .tip {
          font-size: 14px;
          color: #333;
        }
      }

      .step4 {
        text-align: center;

        .box {
          display: flex;
          justify-content: space-between;
          width: 540px;
          margin: 40px auto 46px;

          .item {
            width: 120px;
            height: 120px;
            text-align: center;
            background-color: #fff;
            border-radius: 6px;
            overflow: hidden;

            .head {
              width: 120px;
              height: 6px;
            }

            img {
              width: 40px;
              height: 40px;
              margin-bottom: 12px;
              margin-top: 18px;
            }
          }
        }

        .tip {
          font-size: 14px;
          color: #333;
        }
      }

      .step5 {
        text-align: center;

        .box {
          display: flex;
          justify-content: space-between;
          width: 540px;
          margin: 40px auto 46px;

          .item {
            width: 120px;
            height: 120px;
            text-align: center;
            background-color: #fff;
            border-radius: 6px;
            overflow: hidden;

            .head {
              width: 120px;
              height: 6px;
            }

            img {
              width: 120px;
              height: 72px;
              margin-bottom: 14px;
            }
          }
        }

        .tip {
          font-size: 14px;
          color: #333;
        }
      }
    }
  }
}
</style>
