<template>
  <div :class="$style.home">
    <a-carousel autoplay>
      <img :src="oss + '/manage/media/home/banner01.png'" />
    </a-carousel>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {}
  },
}
</script>

<style lang="less" module>
.home {
  width: 100%;
  height: 180px;

  img {
    object-fit: cover;
  }

  :global {
    .slick-slide {
      height: 180px;
      background-color: #eee;
      text-align: center;
      line-height: 180px;
    }

    .slick-list {
      border-radius: 6px;
      overflow: hidden;
    }
  }
}
</style>
