<template>
  <div :class="$style.home">
    <div
      v-for="item in data"
      :key="item.back"
      :class="$style.box"
      :style="back(item.back)"
    >
      <div :class="$style.left">
        <p class="ellipsis" :class="$style.num">{{ item.num }}</p>
        <p class="ellipsis" :class="$style.name">{{ item.name }}</p>
      </div>
      <img :src="oss + item.icon" />
    </div>
  </div>
</template>

<script>
import { homeService } from "@/service";

const service = new homeService();

export default {
  name: "Home",
  data() {
    return {
      data: [
        {
          name: "文化资源数量",
          num: "960",
          icon: "/manage/icon/home/poc.png",
          back: "linear-gradient(270deg, #1D6FFF 0%, #54ADFF 100%)",
        },
        {
          name: "数字内容数量",
          num: "1460",
          icon: "/manage/icon/home/content.png",
          back: "linear-gradient(270deg, #F35748 0%, #FE895D 100%)",
        },
        {
          name: "总用户数量",
          num: "76700",
          icon: "/manage/icon/home/users.png",
          back: "linear-gradient(90deg, #56DBD1 0%, #00BDBA 100%)",
        },
        {
          name: "账户金额",
          num: "0",
          icon: "/manage/icon/home/account.png",
          back: "linear-gradient(270deg, #8259ED 0%, #C087CF 100%)",
        },
      ],
    };
  },
  created() {
    // this.getInfo();
  },
  methods: {
    async getInfo() {
      const { data } = await service.getStatistics();
      this.data[0].num = data.poc_count;
      this.data[1].num = data.content_count;
      this.data[2].num = data.user_count;
      this.data[3].num = data.balance || 0;
    },
    back(color) {
      return { background: color };
    },
  },
};
</script>

<style lang="less" module>
.home {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .box {
    color: #fff;
    padding: 20px;
    width: calc(25% - 15px);
    height: 112px;
    display: flex;
    align-items: center;
    border-radius: 6px;

    .left {
      flex: 1;
    }

    .num {
      font-size: 30px;
      margin-bottom: 14px;
    }

    .name {
      font-size: 14px;
    }

    i {
      font-size: 40px;
    }

    img {
      width: 48px;
      height: 48px;
      object-fit: cover;
    }
  }
}
</style>
