<template>
  <div :class="$style.home">
    <!-- <div :class="$style.visiter">
      <div :class="$style.header">
        <img :class="$style.img" :src="data.avatar" />
        <div :class="$style.desc">
          <p :class="$style.name">王宗泉</p>
          <p>{{ data.position }}</p>
        </div>
      </div>
      <div :class="$style.contact">{{ data.describe }}</div>
    </div> -->

    <div :class="$style.phone">
      <!-- <p :class="$style.title">客服电话</p>
      <div :class="$style.num">
        <i class="ri-customer-service-line" />
        13618007075
      </div>
      <div :class="$style.btns">
        <BjButton class="mr-20" :class="$style.btn" type="primary"> 在线咨询 </BjButton>
        <BjButton :class="$style.btn"> 产品建议 </BjButton>
      </div> -->

      <p :class="$style.title">关注我们</p>
      <div :class="$style.care">
        <img :src="oss + '/manage/media/home/qrcode.jpg'" />
        <div>
          <p :class="$style.name">运城文旅云</p>
          <p>关注微信公众号，获取更多产品信息！</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { homeService } from "@/service";

const service = new homeService();

export default {
  name: "Home",
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      const { data } = await service.getAdviser();
      this.data = data;
    },
  },
};
</script>

<style lang="less" module>
.home {
  padding: 10px;
  background-color: #fff;
  margin-top: 20px;

  .visiter {
    background-color: @primary-color;
    padding: 20px;
    border-radius: 6px;
    height: 180px;

    .header {
      width: 100%;
      background-color: @primary-color;
      border-radius: 6px;
      display: flex;
      align-items: center;

      .img {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        object-fit: cover;
        margin-right: 20px;
      }

      p {
        margin-bottom: 0;
        font-size: 12px;
        color: #fff;
      }

      .name {
        margin-bottom: 4px;
        font-size: 18px;
      }
    }

    .contact {
      width: 220px;
      height: 60px;
      padding: 10px;
      margin-top: 20px;
      font-size: 13px;
      color: #fff;
      background-color: rgba(256, 256, 256, 0.2);
      border-radius: 6px;
    }
  }

  .phone {
    padding: 0 10px;

    .num {
      font-size: 18px;
      color: #000;
      margin-bottom: 14px;

      i {
        color: @primary-color;
        font-size: 23px;
        margin-right: 20px;
        vertical-align: -4px;
      }
    }

    .btns {
      text-align: center;

      .btn {
        width: 110px;
      }
    }
  }

  .title {
    font-size: 14px;
    color: #000;
    margin-top: 20px;
    margin-bottom: 14px;
  }

  .care {
    display: flex;

    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      margin-right: 20px;
    }

    p {
      margin-bottom: 0;
      font-size: 12px;
    }

    .name {
      font-size: 14px;
      margin-bottom: 4px;
    }
  }
}
</style>
